
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useNotification } from '@/composables'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import { copyToClipboard } from '@/utils/string'
import {
  getOrderStatusColor,
  getAddressStatusColor,
  getWarningContent,
  validateOrderAction,
} from '@/utils/orderUtils'
import { formatTime } from '@/utils/dateTime'
import { addressTitleMap, ADDRESS_STATUS } from '@/utils/constants'
import { UserTypes } from '@/utils/types'
import { ElMessageBox } from 'element-plus'
import RequestSellerDialog from './../RequestSellerDialog.vue'
import { CopyDocument, ArrowDown, Warning } from '@element-plus/icons'

export default defineComponent({
  components: { RequestSellerDialog, CopyDocument, ArrowDown, Warning },
  props: {
    state: {
      type: Object,
      required: true,
    },
    userInfo: {
      type: Object,
      required: true,
    },
    isShowActions: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter()
    const { error, success } = useNotification()

    const {
      approveOrder,
      verifyAddressOrders,
      shipmentCancelOrder,
      agencyUnHoldOrders,
      rejectOrder,
      changeOrderWithOld,
    } = useOrderRepositories()

    const {
      validateApproved,
      validateReject,
      validateCancelShip,
      validateVerifyAddress,
      validateUnHold
    } = validateOrderAction()

    const refresh = () => {
      emit('refresh')
    }

    const copyToClipboardOrderName = (text: string) => {
      copyToClipboard(text)
      success('Copy to clipboard')
    }

    const onSubmitApprove = async (ids: any[]) => {
      try {
        const res = await approveOrder({
          ids: ids,
        })
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Approve order success!`)
          refresh()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const verifyAddressOrder = async (ids: any[]) => {
      try {
        const res = (await verifyAddressOrders({
          ids: ids,
        })) as any
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Verify Address order success!`)
          refresh()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const onSubmitCancelShip = async (ids?: any[]) => {
      try {
        ElMessageBox.prompt('Input cancel reason', 'Cancel order', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
        }).then(async ({ value }) => {
          if (!ids?.length) {
            ids = props.state.multipleSelection.map((order: any) => order._id)
          }
          let { data, status } = await shipmentCancelOrder({
            ids: ids,
            content: value,
          })
          if (status === 200 || status === 201) {
            success('Shipment cancel success!')
            refresh()
          } else {
            error(data.message)
          }
        })
      } catch (err) {
        error()
      }
    }

    const onSubmitUnholdOrder = async (ids?: any[]) => {
      try {
        ElMessageBox.prompt('Input unhold reason', 'Unhold order', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
        }).then(async ({ value }) => {
          if (!ids?.length) {
            ids = props.state.multipleSelection.map((order: any) => order._id)
          }
          let res = await agencyUnHoldOrders({
            ids: ids,
          })
          if (res?.status === 200 || res?.status === 201) {
            success('Shipment cancel success!')
            refresh()
          } else {
            error(res?.data?.message)
          }
        })
      } catch (err) {
        error()
      }
    }

    const onSubmitReject = async (ids?: any[]) => {
      try {
        ElMessageBox.prompt('Input reject reason', 'Reject order', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
        }).then(async ({ value }) => {
          if (!ids?.length) {
            ids = props.state.multipleSelection.map((order: any) => order._id)
          }
          let { data, status } = await rejectOrder({
            ids: ids,
            content: value,
          })
          if (status === 200 || status === 201) {
            success('Reject order success!')
            refresh()
          } else {
            error(data.message)
          }
        })
      } catch (err) {
        error()
      }
    }

    const goToRefundPage = (item: any) => {
      router.push({ name: 'agency.orders.refund', params: { id: item } })
    }

    const requestSellerDialog = ref<InstanceType<typeof RequestSellerDialog>>()
    const onClickRequestSeller = (order: any) => {
      requestSellerDialog.value?.toggle(order)
    }

    const changeOrderOld = async () => {
      const res = await changeOrderWithOld(props.state.order._id)
      if (res?.status === 200) {
        refresh()
        success('Update success!')
      } else {
        error('Error!')
      }
    }

    const isOrderUpdatedToOld = (order: any) => {
      return order?.serverId?.endsWith('_bak')
    }

    return {
      refresh,
      formatTime,
      copyToClipboardOrderName,
      getOrderStatusColor,
      getAddressStatusColor,
      getWarningContent,
      validateApproved,
      validateReject,
      validateCancelShip,
      validateVerifyAddress,
      addressTitleMap,
      ADDRESS_STATUS,
      UserTypes,
      onSubmitApprove,
      verifyAddressOrder,
      onSubmitCancelShip,
      onSubmitReject,
      goToRefundPage,
      requestSellerDialog,
      onClickRequestSeller,
      changeOrderOld,
      isOrderUpdatedToOld,
      onSubmitUnholdOrder,
      validateUnHold
    }
  },
})
