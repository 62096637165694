
import { defineComponent, ref } from 'vue'
import axios from '@/services/axios'
import { useRoute } from 'vue-router'
import CustomerDialog from './../Customer.vue'
import RequestSellerDialog from './../RequestSellerDialog.vue'
import { RequestSellerStatusEnum } from '@/utils/constants'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import { useNotification } from '@/composables'
import { InfoFilled } from '@element-plus/icons-vue'
import { validateOrderAction } from '@/utils/orderUtils'
import { Edit } from '@element-plus/icons'
import GrandTotalOrder from './../GrandTotalOrder.vue'
import OrderSellerTeamCard from './../Cards/OrderSellerTeamCard.vue'

export default defineComponent({
  components: {
    CustomerDialog,
    RequestSellerDialog,
    GrandTotalOrder,
    OrderSellerTeamCard,
    Edit,
  },
  props: {
    state: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { verifyAddressOrders } = useOrderRepositories()
    const { error, success } = useNotification()
    const { validateOrderActionSeller } = validateOrderAction()
    const route = useRoute()

    const customerDialog = ref<InstanceType<typeof CustomerDialog>>()
    const showModalEditCustomer = () => {
      customerDialog.value?.toggleModal(props.data.customer)
    }

    const requestSellerDialog = ref<InstanceType<typeof RequestSellerDialog>>()
    const onClickRequestSeller = (order: any) => {
      requestSellerDialog.value?.toggle(order)
    }

    const reset = () => {
      emit('reset')
    }

    const onAfterResquestSeller = () => {
      reset()
      // fetchRecord()
    }

    const showErrorMsg = (errorMsg: string) => {
      if (errorMsg) {
        return errorMsg.replaceAll('\n', '<br>')
      }
    }

    const verifyAddressOrder = async (ids: any[]) => {
      try {
        const res = (await verifyAddressOrders({
          ids: ids,
        })) as any
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Verify Address order success!`)
          reset()

          // fetchRecord()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const checkingAddress = async () => {
      emit('check-address')
      // props.state.order.addressValid = 3
      // const { data, status } = await checkAddressAgain(
      //   route.params.id as string
      // )
      // if (status === 200 || status === 201) {
      //   success('Success')
      // } else {
      //   error(data.message)
      // }
    }

    const updateAgencyNote = async () => {
      try {
        const resourceUpdate = 'orders/agency-note'
        const agencyNote = props.state.order.agencyNote
        const body = {
          agencyNote,
          orderId: route.params.id,
        }
        const res = await axios.put(resourceUpdate, body)
        if (res?.status === 200) {
          success('Update success!')
        }
      } catch (e) {
        console.log('updateAgencyNote - Error:', e)
      }
    }

    return {
      props,
      InfoFilled,
      RequestSellerStatusEnum,
      validateOrderActionSeller,
      customerDialog,
      showModalEditCustomer,
      requestSellerDialog,
      reset,
      onClickRequestSeller,
      onAfterResquestSeller,
      showErrorMsg,
      verifyAddressOrder,
      checkingAddress,
      updateAgencyNote,
    }
  },
})
