
import { defineComponent } from 'vue'
import { formatterDollars } from '@/utils/formatter'

export default defineComponent({
  components: {},
  props: {
    order: { type: Object, required: true },
  },
  setup() {
    return { formatterDollars }
  },
})
