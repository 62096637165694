
import { defineComponent, reactive, ref } from 'vue'
import { useNotification } from '@/composables'
import { getOrderStatusColor } from '@/utils/orderUtils'
import axios from '@/services/axios'
import { imageUrl } from '@/utils/image'
import { ElMessageBox } from 'element-plus'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import { ORDER_STATUS } from '@/utils/constants'
export default defineComponent({
  props: {
    items: {
      type: Object,
      default: () => {
        return {}
      },
    },
    orderStatus: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const showModalUpdateTracking = ref(false)
    const isLoading = ref(true)
    const orderStatusValue = ref()
    const data = ref()
    const { error, success } = useNotification()

    const state = reactive({
      form: {
        trackingContent: '',
      },
    })

    const toggleModal = () => {
      showModalUpdateTracking.value = !showModalUpdateTracking.value
      orderStatusValue.value = props.orderStatus
      isLoading.value = true
      const myMapData = detectMapDataByTrackingNumbers(props.items)
      data.value = [...myMapData.values()]
      isLoading.value = false
    }

    const detectMapDataByTracking = (items: any) => {
      let myMapData = new Map<string, any>()
      try {
        items.forEach(
          (item: { trackingNumbers: any; carriers: any; status: any }) => {
            const trackingNums = item?.trackingNumbers
              ? item.trackingNumbers
              : []
            if (!myMapData.has(trackingNums)) {
              const value = {
                trackingNumbers: trackingNums,
                carriers: item.carriers,
                items: [item],
                status: item.status,
              }
              myMapData.set(trackingNums, value)
            } else {
              const value = myMapData.get(trackingNums)
              let items = value.items
              items.push(item)
              myMapData.set(trackingNums, value)
            }
          }
        )
      } catch (error) {
        console.log(error)
      }
      return myMapData
    }

    const detectMapDataByTrackingNumbers = (items: any) => {
      let myMapData = new Map<string, any>()
      try {
        items.forEach(
          (item: {
            trackingNumber: any
            carrier: any
            trackingNumbers: any
            carriers: any
            status: any
          }) => {
            let trackingNums
            if (item?.trackingNumbers?.length > 0) {
              trackingNums = item.trackingNumbers
            } else if (item?.trackingNumber) {
              trackingNums = [item.trackingNumber]
            } else {
              trackingNums = []
            }

            let carriers
            if (item?.carriers?.length > 0) {
              carriers = item.carriers
            } else if (item?.carrier) {
              carriers = [item.carrier]
            } else {
              carriers = []
            }

            if (!myMapData.has(trackingNums)) {
              const value = {
                trackingNumbers: trackingNums,
                carriers: carriers,
                items: [item],
                status: item.status,
              }
              myMapData.set(trackingNums, value)
            } else {
              const value = myMapData.get(trackingNums)
              let items = value.items
              items.push(item)
              myMapData.set(trackingNums, value)
            }
          }
        )
      } catch (error) {
        console.log(error)
      }
      return myMapData
    }

    const onSave = async () => {
      //
      console.log(111, state.form.trackingContent)
    }

    const close = () => {
      showModalUpdateTracking.value = false
    }

    const { updateFulfilled } = useOrderRepositories()
    const onUpdateFulfilledOrderItems = (row: any) => {
      try {
        if (!row) {
          error('Can not find data!')
          return
        }
        const ids = row?.items?.map((item: { _id: any }) => item._id)
        if (ids?.length === 0) {
          error('Not found any items!')
          return
        }
        ElMessageBox.confirm(
          'System will update there items to Fulfilled. Continue?',
          'Update Fulfilled Confirm',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            confirmButtonClass: 'primary',
          }
        ).then(async () => {
          const res = (await updateFulfilled({
            ids: ids,
          })) as any
          if (res.status === 200 || res.status === 201) {
            success('Update fulfilled success!')
            close()
            context.emit('updateSuccess')
          } else {
            error(res.message)
          }
        })
      } catch (err) {
        error()
      }
    }

    const onUpdatePickOrderItems = (row: any) => {
      try {
        if (!row) {
          error('Can not find data!')
          return
        }
        const ids = row?.items?.map((item: { _id: any }) => item._id)
        if (ids?.length === 0) {
          error('Not found any items!')
          return
        }
        ElMessageBox.confirm(
          'System will update there items to Fulfilled. Continue?',
          'Update Fulfilled Confirm',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            confirmButtonClass: 'primary',
          }
        ).then(async () => {
          const res = (await updateFulfilled({
            ids: ids,
          })) as any
          if (res.status === 200 || res.status === 201) {
            success('Update fulfilled success!')
            close()
            context.emit('updateSuccess')
          } else {
            error(res.message)
          }
        })
      } catch (err) {
        error()
      }
    }

    return {
      showModalUpdateTracking,
      close,
      toggleModal,
      success,
      error,
      getOrderStatusColor,
      onSave,
      state,
      isLoading,
      data,
      imageUrl,
      onUpdateFulfilledOrderItems,
      onUpdatePickOrderItems,
      orderStatusValue,
      updateFulfilled,
      ORDER_STATUS,
      detectMapDataByTracking,
      detectMapDataByTrackingNumbers,
    }
  },
})
