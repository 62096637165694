
import { defineComponent } from '@vue/runtime-core'
import { getOrderStatusColor } from '@/utils/orderUtils'
import { formatterDollars } from '@/utils/formatter'

export default defineComponent({
  name: `FilterTable`,
  components: {},
  props: {
    item: {
      type: Object,
    },
    userType: {
      type: String,
      default: 'seller',
    },
  },
  setup(props, { emit }) {
    return { getOrderStatusColor, formatterDollars }
  },
})
