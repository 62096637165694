import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_col = _resolveComponent("el-col")!

  return (_openBlock(), _createBlock(_component_el_row, { class: "w-full" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 24 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_row, { class: "text-base font-bold" }, {
                default: _withCtx(() => [
                  _createTextVNode(" Revenue: " + _toDisplayString(_ctx.formatterDollars(_ctx.order?.grandTotal)), 1)
                ]),
                _: 1
              }),
              (_ctx.order?.shippingTotal)
                ? (_openBlock(), _createBlock(_component_el_row, {
                    key: 0,
                    class: "mt-3"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Shipping: " + _toDisplayString(_ctx.formatterDollars(_ctx.order?.shippingTotal)), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.order?.discountAmount)
                ? (_openBlock(), _createBlock(_component_el_row, {
                    key: 1,
                    class: "mt-3"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Discount: " + _toDisplayString(_ctx.formatterDollars(_ctx.order?.discountAmount)), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}