
import { defineComponent, ref, computed, reactive, onMounted } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useRoute } from 'vue-router'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import { ICustomer } from '@/interfaces/order'
import { imageUrl } from '@/utils/image'
import Timeline from '@/components/orders/Timeline.vue'
import UpdateTrackingDialog from './components/UpdateTracking.vue'
import UpdateDeliveryDialog from './components/UpdateDelivery.vue'
import { useNotification } from '@/composables'
import SlotError from '@/components/common/SlotError.vue'
import { formatterDollars } from '@/utils/formatter'
import {
  countDesignQtyOrderItem,
  validateOrderAction,
  changeHistory,
  CHANGE_ORDER_HISTORY,
  validateOrderStatusReCalculate,
  isManagerOrAccounting,
} from '@/utils/orderUtils'
import { ORDER_STATUS, DESIGN_STATUS } from '@/utils/constants'
import { ElMessageBox } from 'element-plus'
import CustomDialog from '@/components/orders/CustomDialog.vue'
import ChangeHistoryContent from '@/components/orders/ChangeHistoryContent.vue'
import ChangeCatalogCodeDialog from '@/components/common/ChangeCatalogCode.vue'
import DetailOrderItem from '@/components/common/DetailOrderItem.vue'
import { validateEditArtworkCheck } from '@/utils/orderUtils'
import UploadImage from '@/pages/seller/orders/components/UploadImage.vue'
import UploadDesign from '@/pages/seller/orders/components/UploadDesign.vue'
import useMappingReponsitories from '@/repositories/useMappingReponsitories'
import userProductRepositories from '@/repositories/userProductRepositories'
import useOrderItemRepositories from '@/repositories/useOrderItemRepositories'
import { ARTWORK_SPACES } from '@/utils/constants'
import OrderFeesCard from './components/Cards/OrderFeesCard.vue'
import OrderProfitCard from './components/Cards/OrderProfitCard.vue'
import OrderInfo from './components/Detail/OrderInfo.vue'
import TitleInfo from './components/Detail/TitleInfo.vue'

export default defineComponent({
  components: {
    sectionLayoutContent,
    Timeline,
    SlotError,
    UpdateTrackingDialog,
    UpdateDeliveryDialog,
    CustomDialog,
    ChangeHistoryContent,
    ChangeCatalogCodeDialog,
    DetailOrderItem,
    UploadImage,
    UploadDesign,
    OrderFeesCard,
    OrderProfitCard,
    OrderInfo,
    TitleInfo,
  },
  setup() {
    const route = useRoute()
    const breadcrumbs = [
      {
        text: 'Orders',
        link: 'agency.orders',
        params: null,
      },
      {
        text: 'Detail',
        link: '',
        params: null,
      },
    ]

    const calculatingOrderBasecost = ref(false)
    const calculatingOrderProfit = ref(false)

    const data = reactive<{
      customer: ICustomer
      rules: any
      isLoading: boolean
      variant: any
      sellerTeams: any
      orderSellerTeamInfo: any
    }>({
      customer: {
        fullName: '',
        address1: '',
        address2: '',
        city: '',
        province: '',
        phone: '',
        email: '',
        country: '',
        countryCode: '',
        zipCode: '',
        errorMsg: '',
      },
      rules: {
        fullName: [
          {
            required: true,
            message: 'Please input Name',
            trigger: 'blur',
          },
          {
            min: 3,
            max: 15,
            message: 'Length should be 3 to 5',
            trigger: 'blur',
          },
        ],
        address1: [
          {
            required: true,
            message: 'Please input Address',
            trigger: 'blur',
          },
          {
            min: 3,
            max: 255,
            message: 'Length should be 3 to 5',
            trigger: 'blur',
          },
        ],
        city: [
          {
            required: true,
            message: 'Please input City',
            trigger: 'blur',
          },
          {
            min: 3,
            max: 255,
            message: 'Length should be 3 to 5',
            trigger: 'blur',
          },
        ],
        zipCode: [
          {
            required: true,
            message: 'Please input Zip',
            trigger: 'blur',
          },
          {
            min: 3,
            max: 25,
            message: 'Length should be 3 to 5',
            trigger: 'blur',
          },
        ],
      },
      isLoading: true,
      variant: {
        variantTitle: '',
        artwork1: '',
        artwork2: '',
        artwork3: '',
        artwork4: '',
      },
      sellerTeams: [],
      orderSellerTeamInfo: {},
    })
    const { warning } = useNotification()
    const {
      checkAddressAgain,
      cancelOrders,
      calcOrderFulfillPrice,
      calcOrderProfit,
      updateOrderItemCatalogCode,
      calcOrderFulfillmentPrice,
      updateOrderChangeHistory,
      updateOrderItemArtwork,
    } = useOrderRepositories()
    const { getOrder, state } = useOrderRepositories()

    const content = computed(() => {
      return { breadcrumbs }
    })

    const { error, success } = useNotification()

    const { ROLE, ACTION_NAME } = CHANGE_ORDER_HISTORY()

    let userInfo: any
    try {
      userInfo = JSON.parse(localStorage?.getItem('info') || '')
    } catch (e) {
      return error("Can't find user info")
    }
    const isEditable = ref<boolean>(isManagerOrAccounting(userInfo))
    const isShowFulfillmentPrice = ref<boolean>(isEditable.value)

    // hàm được gọi sau khi upload change design của item lên
    const changeArtwork = async (uploaded: any) => {
      console.log('changeArtwork')
      updateOrderAfterChangeDesign(uploaded, ACTION_NAME.CHANGE_DESIGN)
    }

    const uploaded = async (uploaded: any) => {
      console.log('uploaded')
      updateOrderAfterChangeDesign(uploaded, ACTION_NAME.UPLOAD_DESIGN)
    }

    const updateOrderAfterChangeDesign = async (
      uploaded: any,
      actionName: string
    ) => {
      const isValidOrderForUpdate = await validOrderBeforeUpdate()
      if (!isValidOrderForUpdate) {
        error(orderCannotUpdateMsg)
        return
      }

      const orderItemId = state.order.items[uploaded.index]?._id?.toString()
      const orderId = state.order?._id?.toString()

      // phuc vu ghi log
      const itemIndex = uploaded.index
      const oldDesignPath = state.order.items[uploaded.index][uploaded.artwork]
      const newDesignPath = imageUrl(uploaded.path)
      if (oldDesignPath === newDesignPath) {
        success('No changed!')
        return
      }

      const dataChange = {
        actionName: actionName,
        itemId: state.order.items[itemIndex]?._id,
        log: {
          designName: uploaded.artwork,
          itemIndex: itemIndex + 1,
          oldDesignPath: oldDesignPath,
          newDesignPath: newDesignPath,
        },
        createdDate: new Date(),
        seller: userInfo
          ? {
              id: userInfo.id,
              agency: userInfo.agency,
              email: userInfo.email,
              sellerTeam: {
                id: userInfo?.sellerTeam?.id,
              },
            }
          : {},
      }
      state.order = changeHistory(state.order, ROLE.SELLER, dataChange)

      const newDesignUrl = imageUrl(uploaded.path)
      // check số lượng design của item sau khi upload thêm desgin lên
      const checkDesignQty = validateDesignQtyOrderItem(
        state.order.items[uploaded.index]
      )
      const designStatus = checkDesignQty
        ? DESIGN_STATUS.MAPPED_UPLOADED
        : DESIGN_STATUS.NONE

      const body = {
        orderItemId,
        artworkName: uploaded.artwork,
        designStatus,
        newDesignUrl,
      }
      const { status } = await updateOrderItemArtwork(body)
      if (status === 200) {
        success('Success')

        // item không phải custom thì sẽ cập nhật vào product để sử dụng lại Design
        if (state.order.items[uploaded.index]?.personalized !== true) {
          updateDesignToProduct(
            state.order.items[uploaded.index]?.productId,
            uploaded.artwork,
            newDesignPath,
            state.order.items[uploaded.index]?.variantTitle,
            uploaded?.isApplyForAllVariants
          )
        }

        // update lịch sử chỉnh sửa
        await updateOrderChangeHistory({
          orderId,
          changeHistory: state.order.changeHistory,
        })
        fetchRecord()
      } else {
        error('Failed')
      }
    }

    const { onUploadDesignOrderItem, onSelectCatalogCodeOrderItem } =
      useMappingReponsitories()

    const updateDesignToProduct = async (
      productId: string,
      artworkName: string,
      artworkUrl: string,
      variantTitle: string,
      isApplyForAllVariants: boolean
    ) => {
      if (!productId || !artworkName || !variantTitle) {
        return
      }
      const dto = {
        name: artworkName,
        url: imageUrl(artworkUrl),
        variantTitle,
        isApplyForAllVariants,
      }
      await onUploadDesignOrderItem(productId, dto)
    }

    const fetchRecord = async () => {
      data.isLoading = true
      let order = await getOrder(route.params.id)
      if (order) {
        data.customer = {
          fullName: order.fullName,
          address1: order.address1,
          address2: order.address2,
          city: order.city,
          province: order.province,
          phone: order.phone,
          email: order.email,
          country: order.country,
          countryCode: order.countryCode,
          zipCode: order.zipCode,
        }
      }
      data.isLoading = false
    }

    const checkingAddress = async () => {
      state.order.addressValid = 3
      const { data, status } = await checkAddressAgain(
        route.params.id as string
      )
      if (status === 200 || status === 201) {
        success('Success')
      } else {
        error(data.message)
      }
    }

    onMounted(async () => {
      await fetchRecord()
    })

    const updateTrackingDialog =
      ref<InstanceType<typeof UpdateTrackingDialog>>()
    const showModalUpdateTracking = () => {
      updateTrackingDialog.value?.toggleModal(state.order.items)
    }

    const updateDeliveryDialog =
      ref<InstanceType<typeof UpdateDeliveryDialog>>()
    const showModalUpdateDelivery = () => {
      updateDeliveryDialog.value?.toggleModal()
    }

    const cancelOrder = async (ids: any[]) => {
      try {
        const res = (await cancelOrders({
          ids: ids,
        })) as any
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Cancel order success!`)
          fetchRecord()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const customDialog = ref<InstanceType<typeof CustomDialog>>()
    const onViewDetailCustom = (custom: any) => {
      customDialog.value?.toggle(custom)
    }

    const validOrderBeforeUpdate = async () => {
      // await fetchRecord()
      const currentOrderStatus = state.order.status
      return (
        currentOrderStatus &&
        (currentOrderStatus === ORDER_STATUS.UNFULFILLED ||
          currentOrderStatus === ORDER_STATUS.HOLD ||
          currentOrderStatus === ORDER_STATUS.IN_REVIEW ||
          currentOrderStatus === ORDER_STATUS.APPROVED ||
          currentOrderStatus === ORDER_STATUS.IN_PRODUCTION ||
          currentOrderStatus === ORDER_STATUS.IN_TRANSIT)
      )
    }

    const validateDesignQtyOrderItem = (item: any) => {
      if (item) {
        const designQty = item.designQty ? item.designQty : 0
        const countDesignOfItem = countDesignQtyOrderItem(item)
        return designQty === countDesignOfItem
      }
    }

    const updateProductCodeToProduct = async (productId: string, data: any) => {
      if (!productId || !data) {
        return
      }
      const dto = {
        variantTitle: data.variantTitle,
        catalogCode: data.catalogCode,
        catalog: data.catalog,
        catalogVariant: data.catalogVariant,
        designQty: data.designQty,
      }
      await onSelectCatalogCodeOrderItem(productId, dto)
    }

    const orderCannotUpdateMsg = 'This order can not be updated.'

    const setCalculatingOrder = (value: boolean) => {
      calculatingOrderBasecost.value = value
      if (state.order?.syncProfit !== 0) {
        calculatingOrderProfit.value = value
      }
    }

    const onAfterChangeVariantCode = async (data: any) => {
      setCalculatingOrder(true)
      try {
        const newCatalogCode = data.variantCode
        const designQty = data.designQty
        const itemId = data.itemId

        const isValidOrderForUpdate = await validOrderBeforeUpdate()
        if (!isValidOrderForUpdate) {
          error(orderCannotUpdateMsg)
          return
        }

        if (newCatalogCode?.length === 0) {
          error('Not found new catalog code!')
          return
        }
        if (itemId?.length === 0) {
          error('itemId is null!')
          return
        }
        const itemNeedUpdateIndex = state.order.items.findIndex(
          (item: { _id: any }) => item._id === itemId
        ) as any
        if (itemNeedUpdateIndex !== -1) {
          // phuc vu ghi log
          const oldCatalogCode =
            state.order.items[itemNeedUpdateIndex].catalogCode
          const oldDesignQty = state.order.items[itemNeedUpdateIndex].designQty

          state.order.items[itemNeedUpdateIndex].catalogCode = newCatalogCode
          state.order.items[itemNeedUpdateIndex].designQty = designQty

          const designStatus = validateDesignQtyOrderItem(
            state.order.items[itemNeedUpdateIndex]
          )
            ? DESIGN_STATUS.MAPPED_UPLOADED
            : DESIGN_STATUS.NONE

          state.order.items[itemNeedUpdateIndex].designStatus = designStatus
          const dataChange = {
            actionName: ACTION_NAME.CHANGE_CATALOG_CODE,
            itemId: state.order.items[itemNeedUpdateIndex]._id,
            log: {
              oldCatalogCode: oldCatalogCode,
              oldDesignQty: oldDesignQty,
              newCatalogCode: newCatalogCode,
              newDesignQty: designQty,
              newDesignStatus: designStatus,
            },
            createdDate: new Date(),
            seller: userInfo
              ? {
                  id: userInfo.id,
                  agency: userInfo.agency,
                  email: userInfo.email,
                  sellerTeam: {
                    id: userInfo?.sellerTeam?.id,
                  },
                }
              : {},
          }
          state.order = changeHistory(state.order, ROLE.SELLER, dataChange)

          // đổi sang edit mỗi item - catalogCode
          let orderItemId = state.order.items[itemNeedUpdateIndex]._id
          let catalogCode = newCatalogCode

          const orderId = state.order?._id?.toString()
          // Chỉ update với những đơn từ InReview trở đi, ko tính Cancel, Reject
          if (validateOrderStatusReCalculate(state.order?.status)) {
            const body = {
              orderId,
              orderItemId,
              catalogCode,
            }

            /**
             * Khi tính toán lại basecost:
             * 1. thay catalogCode mới vào, tính lại giá base
             * 2. hoàn tiền hoặc trừ tiền
             *    2.1. Nếu hoàn tiền thì tạo invoice hoàn tiền
             *    2.2. Trừ tiền
             *      2.2.1: Trừ tiền thành công -> tạo invoice
             *      2.2.2: Trừ tiền thất bại - trả lại kết quả -> thông báo cho seller
             */
            const resCalc: any = await calcOrderFulfillmentPrice(body)

            if (resCalc?.status === 200) {
              const resData = resCalc.data
              const updateSellerBalance = resData.updateSellerBalance
              const orderPriceChanged = resData.orderPriceChanged

              // Failed: xảy ra khi cần trừ thêm tiền mà seller lại ko đủ balance
              let message = '' //updateSellerBalance?.message
              let balanceNeedTopup = formatterDollars(
                Math.abs(updateSellerBalance?.balance?.amount ?? 0)
              )
              if (updateSellerBalance?.status === 'Success') {
                const invoiceType = orderPriceChanged > 0 ? 'credit' : 'debit'
                if (updateSellerBalance?.balance?.amount !== 0) {
                  message = `Your balance will ${invoiceType} ${balanceNeedTopup} for changed code.`
                  warningTopupWhenChangeCatalogCode(message)
                }
              } else if (updateSellerBalance?.status === 'Failed') {
                message = `Please topup ${balanceNeedTopup} to change this code.`
                warningTopupWhenChangeCatalogCode(message)
                setCalculatingOrder(false)
                return
              }
            } else {
              error(`Recalculate price of order error!`)
            }
          }

          const bodyUpdateCatalogCode = {
            orderItemId,
            catalogCode,
            designQty,
            catalogId: data?.selectedCatalog?.catalogId,
            supplierCode: data?.selectedCatalog?.supplierCode,
            catalogVariantId: data?.selectedCatalog?.catalogVariantId,
          }
          const { status } = await updateOrderItemCatalogCode(
            bodyUpdateCatalogCode
          )

          if (status === 200 || status === 201) {
            success('Edit order success!')
            // update lịch sử chỉnh sửa
            await updateOrderChangeHistory({
              orderId,
              changeHistory: state.order.changeHistory,
            })
            // update vào variant của product
            let updateCatalogCodeDto = {
              variantTitle:
                state.order.items[itemNeedUpdateIndex]?.variantTitle,
              catalogCode: newCatalogCode,
              catalog: data?.selectedCatalog,
              catalogVariant: data?.selectedVariant,
              designQty: data?.designQty,
            }
            if (data?.isApplyForProduct === true) {
              updateProductCodeToProduct(
                state.order.items[itemNeedUpdateIndex]?.productId,
                updateCatalogCodeDto
              )
            }
            // tính lại profit
            if (
              state.order?.syncProfit !== 0 &&
              validateOrderStatusReCalculate(state.order?.status)
            ) {
              await calcOrderProfit(orderId)
            }
            fetchRecord()
          } else {
            error(`Change catalog code fail`)
          }
        }
      } catch (e) {
        console.log('onAfterChangeVariantCode ~ e:', e)
      }
      setCalculatingOrder(false)
    }

    const warningTopupWhenChangeCatalogCode = (message: string) => {
      ElMessageBox.alert(message, 'Warning topup', {
        confirmButtonText: 'Confirm',
      })
    }

    const changeCatalogCodeDialog =
      ref<InstanceType<typeof ChangeCatalogCodeDialog>>()
    const onChangeCatalogCode = (item: any) => {
      if (!item) {
        error('Not found item!')
        return
      }
      changeCatalogCodeDialog.value?.toggle(item)
    }

    const reCalculateOrderBaseCost = async () => {
      try {
        if (calculatingOrderBasecost.value) {
          warning('Running calculate ...')
          return
        }
        calculatingOrderBasecost.value = true
        const orderId = state.order?._id?.toString()
        const res: any = await calcOrderFulfillmentPrice({ orderId })
        if (res?.status === 200 || res?.status === 201) {
          success('Calculate order cost success!')
          fetchRecord()
        } else {
          error('Error when calculate!')
        }
      } catch (e) {
        console.log('reCalculateOrderBaseCost ~ e:', e)
      }
      calculatingOrderBasecost.value = false
    }

    const reCalculateOrderProfit = async () => {
      try {
        if (calculatingOrderProfit.value) {
          warning('Running calculate ...')
          return
        }
        calculatingOrderProfit.value = true
        const orderId = state.order?._id?.toString()
        if (state.order?.syncProfit !== 0) {
          const res: any = await calcOrderProfit(orderId)
          if (res?.status === 200 || res?.status === 201) {
            success('Calculate order profit success!')
            fetchRecord()
          } else {
            error('Error when calculate !')
          }
        } else {
          error('Profit cannot be calculated !')
        }
      } catch (e) {
        console.log('reCalculateOrderProfit ~ e:', e)
      }
      calculatingOrderProfit.value = false
    }

    const reCalculateOrderBaseCostAndProfit = async () => {
      try {
        const orderId = state.order?._id?.toString()
        await calcOrderFulfillPrice(orderId)
        if (state.order?.syncProfit !== 0) {
          await calcOrderProfit(orderId)
        }
      } catch (e) {
        console.log('reCalculateOrderBaseCostAndProfit ~ e:', e)
      }
    }

    const onChangeVariantTitle = async (item: any) => {
      const productId = item?.productId?.toString()
      if (!productId) {
        error('Not found product!')
        return
      }
      item.loadingProduct = true
      item.selectedVariantTitle = item.variantTitle
      const { status, data } = await getProduct(productId)
      if (status === 200 && data) {
        let { _id, variants, title } = data as any
        item.product = { _id, variants, title }
        item.editVariantTitle = true
      }
      item.loadingProduct = false
    }

    const { getProductById } = userProductRepositories()

    const getProduct = async (productId: string) => {
      return await getProductById(productId)
    }

    const { updateOrderItemVariantTitle } = useOrderItemRepositories()
    const onSaveChangeVariantTitle = async (item: any) => {
      const orderItemId: string = item?._id?.toString()
      if (!orderItemId) {
        error('Not found order item!')
        return
      }
      const {
        variantTitle: currentVariantTitle,
        selectedVariantTitle: newVariantTitle,
      } = item

      if (currentVariantTitle === newVariantTitle) {
        success('No changed!')
        return
      }

      const body = {
        orderItemId,
        currentVariantTitle,
        newVariantTitle,
      }
      const res = await updateOrderItemVariantTitle(body)
      if (res?.status === 200 || res?.status === 201) {
        item.variantTitle = newVariantTitle
        item.editVariantTitle = false
        success('Change variant success!')
      }
    }

    return {
      onSaveChangeVariantTitle,
      onChangeVariantTitle,
      getProduct,
      calculatingOrderBasecost,
      calculatingOrderProfit,
      reCalculateOrderBaseCostAndProfit,
      reCalculateOrderBaseCost,
      reCalculateOrderProfit,
      onViewDetailCustom,
      checkAddressAgain,
      changeCatalogCodeDialog,
      onAfterChangeVariantCode,
      onChangeCatalogCode,
      customDialog,
      content,
      state,
      data,
      getOrder,
      uploaded,
      imageUrl,
      fetchRecord,
      formatterDollars,
      showModalUpdateTracking,
      updateTrackingDialog,
      ORDER_STATUS,
      validateOrderAction,
      updateDeliveryDialog,
      showModalUpdateDelivery,
      userInfo,
      cancelOrder,
      validateEditArtworkCheck,
      updateOrderAfterChangeDesign,
      changeArtwork,
      onUploadDesignOrderItem,
      onSelectCatalogCodeOrderItem,
      checkingAddress,
      warningTopupWhenChangeCatalogCode,
      validateOrderStatusReCalculate,
      ARTWORK_SPACES,
      isEditable,
      isShowFulfillmentPrice,
    }
  },
})
