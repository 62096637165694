
import { defineComponent, reactive, ref } from 'vue'
import { useNotification } from '@/composables'
import { getOrderStatusColor } from '@/utils/orderUtils'
import axios from '@/services/axios'
import { imageUrl } from '@/utils/image'

export default defineComponent({
  props: {
    items: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  setup(props, context) {
    const showModalUpdateTracking = ref(false)
    const idLoading = ref(true)
    const { error, success } = useNotification()

    const state = reactive({
      items: [
        {
          _id: '',
          trackingNumbers: [],
          carriers: [],
          trackingNumber: '',
          carrier: '',
        },
      ],
    })

    const toggleModal = (items: any[]) => {
      // detect trackingNumbers and carriers
      items.forEach(element => {
        if (
          element?.trackingNumbers?.length === 0 &&
          element?.trackingNumber?.length > 0
        ) {
          element.trackingNumbers = [element.trackingNumber]
        }
        if (element?.carriers?.length === 0 && element?.carrier?.length > 0) {
          element.carriers = [element.carrier]
        }
      })

      state.items = JSON.parse(JSON.stringify(items))

      showModalUpdateTracking.value = !showModalUpdateTracking.value
      idLoading.value = false
    }

    const onSaveTracking = async () => {
      let params: any[] = []
      state.items.forEach(item => {
        const tmpItem = {
          id: item._id,
          trackingNumber:
            item.trackingNumbers.length > 0 ? item.trackingNumbers[0] : '',
          carrier: item.carriers.length > 0 ? item.carriers[0] : '',
          trackingNumbers: item.trackingNumbers,
          carriers: item.carriers,
        }
        params.push(tmpItem)
      })
      try {
        const res = await axios.patch(
          '/order-items/bulk-update-tracking',
          params
        )
        if (res.status === 200 || res.status === 201) {
          success('Update tracking success!')
          close()
          context.emit('updateSuccess')
        } else {
          error('Error!')
        }
      } catch (error) {
        console.log(error)
      }
    }

    const close = () => {
      showModalUpdateTracking.value = false
    }

    return {
      state,
      showModalUpdateTracking,
      close,
      toggleModal,
      success,
      error,
      onSaveTracking,
      getOrderStatusColor,
      imageUrl,
      idLoading,
    }
  },
})
