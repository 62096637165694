
import { defineComponent, onMounted, ref } from 'vue'
import useUserRepositories from '@/repositories/useUserRepositories'
import { formatterDollars } from '@/utils/formatter'

export default defineComponent({
  components: {},
  props: {
    sellerTeamId: {
      type: String,
    },
  },
  setup(props) {
    const sellerTeam = ref({})
    const isLoading = ref(false)
    const { getSellerTeamById } = useUserRepositories()

    const getOrderSellerTeamInfo = async () => {
      isLoading.value = true
      if (props.sellerTeamId) {
        const res = await getSellerTeamById(props.sellerTeamId)
        if (res?.status === 200) {
          sellerTeam.value = res.data
        }
      }
      isLoading.value = false
    }

    onMounted(async () => {
      await getOrderSellerTeamInfo()
    })

    return { sellerTeam, formatterDollars, isLoading }
  },
})
