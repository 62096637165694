
import { defineComponent, reactive, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import { useNotification } from '@/composables'
import { required } from '@/utils/formRules'

export type ICustomer = {
  fullName: string
  address1: string
  address2: string
  city: string
  province: string
  phone: string | number
  email: string
  country: string
  countryCode: string
  zipCode: string | number
}
export default defineComponent({
  props: {
    customer: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  setup(props, context) {
    const showModalEditCustomer = ref(false)
    const { editCustomer } = useOrderRepositories()
    const { params } = useRoute()
    const state = reactive<{ customer: ICustomer }>({
      customer: {
        fullName: '',
        address1: '',
        address2: '',
        city: '',
        province: '',
        phone: '',
        email: '',
        country: '',
        countryCode: '',
        zipCode: '',
      },
    })
    const rules = computed(() => {
      let defaultRules = {
        fullName: required('full name'),
        address1: required('address 1'),
        city: required('city'),
        countryCode: required('country code'),
        zipCode: required('ZIP/Postal Code'),
        email: [{}, {}],
      }
      return defaultRules
    })

    const formEditCustomer = ref()
    const { error, success } = useNotification()
    const onEditCustomer = () => {
      formEditCustomer.value.validate(async (valid: any) => {
        if (valid) {
          const { status, data } = await editCustomer(
            params.id as string,
            state.customer
          )
          if (status === 201) {
            success('Update success')
            close()
            context.emit('updateSuccess')
          } else {
            error(data.message)
          }
        } else {
          error('error submit!!')
          return false
        }
      })
    }

    const toggleModal = (customer: ICustomer) => {
      state.customer = customer
      showModalEditCustomer.value = !showModalEditCustomer.value
    }

    const close = () => {
      showModalEditCustomer.value = false
      formEditCustomer.value?.resetFields()
    }

    return {
      state,
      rules,
      formEditCustomer,
      onEditCustomer,
      showModalEditCustomer,
      toggleModal,
      close,
    }
  },
})
